// import ScrollTrigger from '@terwanerik/scrolltrigger'
// import Swiper from 'swiper/bundle'
import { LuminousGallery } from 'luminous-lightbox'

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// ++  Main app
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
window.gi = window.gi || {}

$(function () {
  // アンカーリンクのスクロール
  $('a[href^="#"]').click(function () {
    var adjust = 0
    var speed = 600
    var href = $(this).attr('href')
    var target = $(href == '#' || href == '' ? 'html' : href)
    var position = target.offset().top + adjust
    // スムーススクロール linear（等速） or swing（変速）
    $('body,html').animate({ scrollTop: position }, speed, 'swing')
    return false
  })

  // nav toggle
  $('.js-navToggle__btn').on('click', function () {
    $(this).parents('.js-navToggle').toggleClass('is-active')
  })
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.js-navToggle').length) {
      $('.js-navToggle').removeClass('is-active')
    }
  })

  // 画像の拡大表示
  const $luminousLink = document.querySelectorAll('.js-luminousLink')
  if ($luminousLink.length > 0) {
    var galleryOpts = {
      arrowNavigation: false,
    }
    var luminousOpts = {
      namespace: 'p-illustSample-modal',
    }
    new LuminousGallery($luminousLink, galleryOpts, luminousOpts)
  }

  // クリップボードに指定テキストをコピー
  $('.js-copyName').on('click', function () {
    let imgName = $(this).attr('data-copyName')
    navigator.clipboard.writeText(imgName).then(() => {
      $('.js-notification-copy').addClass('is-show')
      setTimeout(() => {
        $('.js-notification-copy').removeClass('is-show')
      }, 3000)
    })
  })

  // 要素クリックで、親要素のformを送信
  $('.js-sendFormBtn').on('click', function (e) {
    console.log('js-sendFormBtn')
    $(this).parents('form').submit()
  })

  // フォームの2重送信禁止
  $('.js-blockDblSend').on('click', function (e) {
    e.preventDefault()
    $(this).addClass('is-disabled')
    $(this).prop('disabled', true)
    $(this).parents('form').submit()
  })
})
